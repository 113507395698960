import { Component, Input, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @Input() title = 'Dashboard'

  user: any
  clients:any = []
  selectedClient:any;

  constructor(
    private clientsService: ClientsService,
  ) { }

  ngOnInit(): void {
   let localUser = localStorage.getItem('user')?.toString()
   if(localUser) this.user = JSON.parse(localUser)
  
    let client_id = localStorage.getItem('client_id')?.toString()
    this.selectedClient = client_id ? client_id : this.user.client_id

   if(this.user.group_id == 3){
      this.getClients()
   }
  }

  getClients(){
    this.clientsService.getAll().subscribe({
      next: (result: any) => {
        this.clients = result.data
      },
      error: (err) => console.log(err)
    })
  }

  setClientNavigation(id:any) {
    this.clientsService.setClientNavigation(id).subscribe({
      next: () => { 
        localStorage.setItem('client_id', id)
        window.location.reload();
      },
      error: (err) => console.log(err)
    })
  }
}
