import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  user:any
  roles:any
  version: any
  solicitations = false;

  @Input() menu: any = []

  constructor(
    private service: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.version = require('../../../../../package.json').version;
    let localUser = localStorage.getItem('user')?.toString()
    if(localUser) this.user = JSON.parse(localUser)

    let roles = localStorage.getItem('roles')?.toString()
    if(roles) this.roles = JSON.parse(roles).map((role:any) => role.slug)

    console.log(this.user)

    this.solicitations = localStorage.getItem('client_id') == '2';

  }

  logOut() {
    Swal.fire({
      icon: 'warning',
      text: `Deseja realmente sair?`,
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then((result) => {
      if (result.value) {  
        this.service.logout()
        this.router.navigate(['/login']);
      }
    });
  }
}
