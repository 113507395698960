import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChatService } from '../../services/demands/chat.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  @ViewChild('chatList') private chatList!: ElementRef;
  
  @Input() demandId!: number;
  @Input() list = true;
  comments:any = [];
  chatForm!: FormGroup; 
  user:any;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService
  ) {}

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }

  ngOnInit(): void {
    let localUser = localStorage.getItem('user')?.toString()
    if(localUser) this.user = JSON.parse(localUser)

    this.loadComments();

    this.chatForm = this.formBuilder.group({
      'comment': ['', Validators.required],
      'user_id': this.user.id,
      'demand_id': this.demandId
    });
  }

  loadComments(): void {
    console.log(this.demandId);
    console.log(typeof this.demandId);
    this.chatService.getChatsByDemandID(this.demandId).subscribe({
      next: (result) => { 
        console.log(result);
        this.comments = result;
      },
      error: (err) => console.log(err)
    });
  }

  sendComment(data:any): void {
    this.loading = true;
      this.chatService.save(data.value).subscribe({
        next: (result) => {
          this.chatForm.patchValue({ comment: '' })
          this.comments.push(result);
          this.loading = false;
        },
        error: () => { }
      });
  }

  scrollToBottom(): void {
    try {
      this.chatList.nativeElement.scrollTop = this.chatList.nativeElement.scrollHeight;
    } catch(err) { 
      console.error('Scroll error:', err);
    }
  }


}
