import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-box-kanban',
  templateUrl: './box-kanban.component.html',
  styleUrls: ['./box-kanban.component.scss']
})
export class BoxKanbanComponent implements OnInit {

  @Input() context = 'demand'
  @Input() openDemandPopup = true;
  @Output() updateBoxStatus = new EventEmitter()

  status: any = []
  skeleton = true

  demand_id: any = 0

  header: any

  constructor(
    private service: DemandsService,
    private statusService: StatusService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getStatus()
  }

  getStatus() {
    this.statusService.getKanban(this.context).subscribe({
      next: (result) => { 
        console.log(result);
        this.status = result
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    console.log(status)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.service.updateStatus({ id: event.item.data.id, status_id: status.id, context: status.context }).subscribe({
        next:  () => this.updateBoxStatus.emit(true),
        error: (err) => console.log(err)
      })
    }
  }

  onColumnDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    const ids = this.status.map((item: any) => item.id);
    this.statusService.setNewOrder({ array_ids: ids }).subscribe({
      next: () => {},
      error: (err) => console.log(err)
    })
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  demandHeader(data: any) {
    this.header = data
  }

}
