import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService extends BaseService {

  getActivityLog(log_name = 'demand', id: number) { 
    return this.get(`${log_name}/${id}/logs`);
  }

}
