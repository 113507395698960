import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService extends BaseService {

  private startTime = new BehaviorSubject<Date | null>(null);
  startTime$ = this.startTime.asObservable();

  setStartTime(start: Date | null): void {
    this.startTime.next(start);
  }

  start(data:any) { 
    return this.post(`timesheets/start`, data)
  }

  stop (data:any) { 
    return this.post(`timesheets/stop`, data)
  }

  getByDemandId(demand_id:number) {
   return this.get(`timesheets/${demand_id}`);
  }

  getUserActiveTimesheet() {
   return this.get(`timesheets/active`);
  }

}
