import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import Swal from 'sweetalert2';
import { CategoryService } from '../../services/data/category.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-requester-demands-form',
  templateUrl: './requester-demands-form.component.html',
  styleUrls: ['./requester-demands-form.component.scss']
})
export class RequesterDemandsFormComponent implements OnInit {
  
  skeleton = true;

  @Output() close = new EventEmitter();

  //user
  currentUser:any;

  //demand
  demandForm:any;
  loading =  false;

  //categories
  categories:any;
  selectedCategories:any;

  //form
  formData = new FormData();

  criticalityOptions = [
    { id: 1, label: 'Baixa', slug:'low' },
    { id: 2, label: 'Alta', slug:'high' },
    { id: 3, label: 'Média', slug:'medium' },
    { id: 4, label: 'Urgente', slug:'urgent' }
  ];

  countries = [
    {
      country: "Brasil",
      code: "+55",
      emoji: "🇧🇷"
    },
    {
      country: "Estados Unidos",
      code: "+1",
      emoji: "🇺🇸"
    },
    {
      country: "Argentina",
      code: "+54",
      emoji: "🇦🇷"
    },
    {
      country: "Portugal",
      code: "+351",
      emoji: "🇵🇹"
    },
    {
      country: "Espanha",
      code: "+34",
      emoji: "🇪🇸"
    }
]

  selectedCountry:any = 'BR';

  constructor(
    private formBuilder:FormBuilder,
    private demandService: DemandsService,
    private categoryService: CategoryService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.demandForm = this.formBuilder.group({
      'name': ['',            Validators.required],
      'requester': ['',       Validators.required],
      'email': ['',           Validators.required],
      'user_id': ['',         Validators.required],
      'region': ['',          Validators.required],
      'phone': ['',           Validators.required],
      'criticality': ['',     Validators.required],
      'categories':           this.formBuilder.array([]),
      'emoji_id': ['',        ],
    })

    this.getOptions();
    
    let localUser = localStorage.getItem('user')?.toString()
    if(localUser) this.currentUser = JSON.parse(localUser)
    
    console.log(this.currentUser);

    this.demandForm.controls['requester'].setValue(this.currentUser.name);
    this.demandForm.controls['user_id'].setValue(this.currentUser.id);
    this.demandForm.controls['email'].setValue(this.currentUser.email ?? '');
    this.demandForm.controls['region'].setValue('+55');
    this.demandForm.controls['phone'].setValue(this.currentUser.phone ?? '');

    this.demandForm.controls['requester'].disable();
    this.demandForm.controls['user_id'].disable();
    this.demandForm.controls['email'].disable();
  
  }

  addEmoji(emojiId:any){
    this.demandForm.controls['emoji_id'].setValue(emojiId);
  }

  onChange(category: any, optionId: any, event: any) {
    const isChecked = event.target.checked;
  
    if (category.multiple) {
      if (isChecked) {
        category.selecteds.push(optionId);
      } else {
        const index = category.selecteds.indexOf(optionId);
        if (index > -1) {
          category.selecteds.splice(index, 1);
        }
      }
    } else {
      category.selecteds = [optionId];
    }
    console.log(category)
  }

  getOptions() {
    this.skeleton = true;
    this.categoryService.getAll().subscribe({
      next: (result: any) => {
        this.categories = result.data;
        this.skeleton = false;
        if (this.categories) {
          this.categories.forEach((c: any) => {
            c.selecteds = [];
          });
        }
        console.log(this.categories)
      },
      error: (err) => console.log(err)
    })
  }

  getInputsValue() {
    let s: any = []
    console.log(this.categories);
    this.categories.forEach((element: any) => {
      if (element.selecteds.length > 0) {
        element.selecteds.forEach((id: any) => {
          this.formData.append("categories[]", id);
        });
      }
      console.log('categories')
      console.log(s)
    });
  }

  save(data: any) {
    console.log(data);
    this.loading = true

    Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;

      // Verifica se o valor não é nulo ou indefinido
      if (value !== null && value !== undefined) {
        this.formData.append(key, value);
        console.log(this.formData.getAll(key));
      }
    })

    console.log(this.demandForm)

    this.getInputsValue();

    this.demandService.save(this.formData).subscribe({
      next: (result: any) => {
        console.log(result);

        this.loading = false;

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Solicitação enviada com sucesso',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
            this.close.emit();
            this.router.navigateByUrl(`/requester/demands`);
        })

      },
      error: (err) => {
        console.log(err)
        this.loading = false
      }
    })
  }

}
