import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-default',
  templateUrl: './page-default.component.html',
  styleUrls: ['./page-default.component.scss']
})
export class PageDefaultComponent implements OnInit {

  @Input() title = 'Dashboard'
  @Input() modal = false
  @Input() menu: any = []

  constructor() { }

  ngOnInit(): void {
  }

}
