import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';
import { passwordComplexityValidator } from 'src/app/shared/validators/password-complexity.validator';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { NgxOtpInputConfig } from 'ngx-otp-input';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  registerForm: any;
  pinForm: any;
  loading = false;
  user_hashid:any;
  pin:any = null;
  otpConfig:NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    autoblur: true,
    numericInputMode: true,
    classList: {
      container: 'custom-container',
      inputBox: 'custom-input-box',
      input: 'custom-input',
      inputFilled: 'custom-input-filled',
      inputSuccess: 'custom-input-success',
      inputError: 'custom-input-error'
    }
  };

  constructor(
    private formBuilder:    FormBuilder,
    private service:        UserService,
    private router:         Router,
    private auth:           AuthService,
    private clientsService: ClientsService
  ) { }

  ngOnInit(): void {

    this.clientsService.setClientNavigation("2");
    localStorage.setItem('client_id', "2")

    this.registerForm = this.formBuilder.group({
      'name'    : ['', Validators.required],
      'email'    : ['', Validators.required],
      'password' : ['', [Validators.required, passwordComplexityValidator()]]
    })
    this.pinForm = this.formBuilder.group({
      'pin'    : ['', Validators.required]
    })
  }

  register(data: any) {
    this.loading = true
    this.service.register(data.value).subscribe({
      next:  (result:any) => {
        console.log(result);
        this.user_hashid = result.hashid;
        this.loading = false;
        this.router.navigateByUrl(`otp/verification/${this.user_hashid}`);
      },
      error: (err) => {
        console.log(err)
        this.loading = false;

        Swal.fire({
          icon: 'warning',
          text: err.error.message,
          confirmButtonText: 'Ok'
        });
      }
    });
  }
}
