import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-demand-table',
  templateUrl: './demand-table.component.html',
  styleUrls: ['./demand-table.component.scss']
})
export class DemandTableComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pcbjg.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  @Input() limit: any = null
  @Input() solicitations:boolean = false
  @Input() title = 'DEMANDAS'
  @Input() sharedUrl: any
  @Input() emptyTableText = 'Nenhuma demanda no momento'
  @Input() context:any;
  demands: any = []
  demand_id: any = 0

  skeleton = true
  page = 1
  pagination: Array<number> = []
  query = ''
  order = 'desc'

  client: any

  header: any

  constructor(
    private service: DemandsService, 
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
   this.getDemands()
  }

  getDemands() {
    this.skeleton = true
    this.route.paramMap.subscribe(params => {
      let status = params.get('status')
      
      let filter = { 
        status:   status, 
        limit:    this.limit, 
        query:    this.query,
        order:    this.order,
        page:     this.page,
        context:  this.context,
        solicitations: this.solicitations
      }

      this.service.getAll(filter).subscribe({
        next: (result: any) => {
          console.log('DEMANDAS', result)
          this.demands = result
          console.log(result)
          this.limit ? this.demands.data = this.demands.data.slice(0, this.limit) : ''
          this.skeleton = false
          if(this.pagination.length == 0) {
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
          }
        },
        error: err => console.log(err)
      })
    })
  }

  searchItens(query: any) {
    this.query = query
    this.getDemands()
  }

  orderItens(order: any) {
    this.order = order
    this.getDemands()
  }

  goPage(p: number) {
    this.page = p
    this.getDemands()
  }

  copyToClipboard() {
    this.clipboard.copy(`${this.sharedUrl}`);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Copiado para a área de transfência',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  demandHeader(data: any) {
    this.header = data
  }

}
