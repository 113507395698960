import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  getAll(filter: any = null) {
    return this.post(`categories/filter`, filter)
  }

  getById(id: number) {
    return this.get(`categories/${id}`)
  }

  updateStatus(data: any) {
    return this.post(`categories/status`, data)
  }

  save(data: FormData) {
    return this.post(`categories`, data)
  }

  update(data: FormData, id: number) {
    return this.post(`categories/${id}`, data)
  }

  deleteById(id: number) {
    return this.delete(`categories/${id}`)
  }

  saveCategoryValue(data: FormData, category_id: number) {
    return this.post(`categories/${category_id}/values`, data)
  }

  deleteCategoryValue(id: number) {
    return this.delete(`categories/values/${id}`)
  }

  updateCategoryValue(data: any) {
    return this.post(`categories/values/${data.id}`, data)
  }
}
