<main class="main-content main-content-bg mt-0 login-page">
    <div class="page-header min-vh-100" style="background: url('../../../assets/argon-dashboard-master/assets/img/claro/bg-login.png'); background-size: cover;">
        <!-- <span class="mask bg-gradient-dark opacity-8"></span> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card login border-0 mb-0 bg-transparent shadow-none">
                        <div class="card-header bg-transparent pb-0">
                            <!-- <h5 class="text-dark text-center mt-2">Álamo Gestor</h5> -->
                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/icon-user-login.svg" width="" class="d-block m-auto" alt="">
                        </div>
                        <div class="card-body px-lg-5 pt-2">
                            <div class="text-center text-muted mb-5 mt-4">
                                <h3 class="text-white">Comunicação, treinamento, processos, campanhas motivacionais e solar</h3>
                            </div>
                            <form [formGroup]="loginForm" class="text-start">
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                        <span class="input-group-text border-0 border-radius-0" id="basic-addon1"><img src="../../../assets/argon-dashboard-master/assets/img/claro/user.svg" alt=""></span>
                                    </div>
                                    <input type="email" class="form-control border-0 shadow-none ps-2" formControlName="email" placeholder="Email" aria-label="Email">
                                </div>
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                      <span class="input-group-text border-0 border-radius-0" id="basic-addon1"><img src="../../../assets/argon-dashboard-master/assets/img/claro/lock.svg" alt=""></span>
                                    </div>
                                    <input type="password" class="form-control border-0 shadow-none ps-2" formControlName="password" placeholder="Senha" aria-label="Password">
                                  </div>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input mt-0 me-2 ms-auto" type="checkbox" id="rememberMe">
                                    <label class="form-check-label text-xs text-white m-0" for="rememberMe">Lembrar-se de mim</label>
                                </div>
                                <div class="text-center d-flex mx-auto mb-1">
                                    <button type="submit" 
                                        (click)="login(loginForm)"
                                        [disabled]="loading || !loginForm.valid"
                                        class="btn btn-login text-white w-100 my-4 mb-2">
                                        Entrar
                                    </button>
                                    <a type="submit" 
                                        routerLink="/register"
                                        class="btn btn-secondary text-white w-100 ms-2 my-4 mb-2">
                                        Faça uma solicitação
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>