<form [formGroup]="timesheetForm" class="timesheet-card" [ngClass]="open ? 'show' : 'hide'" *ngIf="this.timer">
    <div class="close" role="button" (click)="open = !open"><i class="fa-solid fa-chevron-right"></i></div>
    <div class="form-group bg-white mb-0 p-3">
        <div class="d-flex">
            <input type="text" id="timer" formControlName="duration" [value]="timer ? timer.hours+':'+timer.minutes+':'+timer.seconds : '00:00:00'" id="timer" [class.readonly]="stopped">
            <button class="stop-button" role="button" id="stopButton" (click)="stopTimer()" *ngIf="timer && !stopped">Parar</button>
        </div>
        <div class="d-flex mt-3" *ngIf="stopped">
            <input type="text" name="title"  id="title" formControlName="title" class="form-control" placeholder="Digite aqui...">
            <button class="save-button" role="button" id="saveButton" [disabled]="!timesheetForm.valid" (click)="save()" *ngIf="!saved">Salvar</button>
            <span class="success-submit-icon" *ngIf="saved"><i class="fa-solid fa-check"></i></span>
        </div>
      <label class="job-title mt-3 mb-0">Job 9814.2 DEV - GESTOR DE DEMANDAS CLARO</label>
    </div>
</form>