import { Injectable } from '@angular/core';
import { 
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest }
from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ClientsService } from '../shared/services/user-management/clients.service';
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private clientsService: ClientsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const globalClientId:any = this.clientsService.global_client_id; // Acessando o valor atualizado

    const modifiedReq = req.clone({
      setHeaders: {
        'global-client-id': globalClientId,
      },
    });
    return next.handle(modifiedReq);
  }
}