import { Component, Input, OnInit } from '@angular/core';
import { StatusService } from '../../services/demands/status.service';

@Component({
  selector: 'app-box-status-overview',
  templateUrl: './box-status-overview.component.html',
  styleUrls: ['./box-status-overview.component.scss']
})
export class BoxStatusOverviewComponent implements OnInit {

  skeleton = true;
  status:any
  @Input() context:any;
  @Input() columns:number = 12;
  @Input() new_demand:boolean = false;
  @Input() identifiers:any

  constructor(
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.getWorkflowStatus();
  }

  floor(num: number): number {
    return Math.floor(num);
  }

  getWorkflowStatus() {
    let filter:any = { 
      context: this.context,
      limit: 1000
    }

    if (this.identifiers) filter.identifiers = this.identifiers

    this.statusService.getAll(filter).subscribe({
      next: (result: any) => {
        console.log(result);
        this.status = result
        this.skeleton = false;
      },
      error: (err) => console.log(err)
    })
  }
}
