import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimesheetService } from '../../services/demands/timesheet.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {

  timesheetForm:any
  start: any;
  timer: any;
  timesheet:any;
  
  saved:boolean = false;
  loading:boolean = false;
  open:boolean = false;
  stopped:boolean = false;

  timerSubscription?: Subscription;

  constructor(private timesheetService: TimesheetService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {

    this.timesheetForm = this.formBuilder.group({
      "title":    ['', Validators.required],
      "duration": [{ value: '', disabled: true }, Validators.required],
    });

    this.timesheetService.getUserActiveTimesheet().subscribe({
      next: (result:any) => { 
        this. timesheet = result;
        if(this.timesheet) this.startTimer(new Date(this.timesheet.start.replace(' ', 'T')))
      },
      error: (err) => console.log(err)
    });

    this.timesheetService.startTime$.subscribe((start) => {
      if (start && !this.timesheet) {
        this.loading = true;
        this.timesheetService.getUserActiveTimesheet().subscribe({
          next: (result) => { 
            if (!result) this.startNewTimesheet();
          },
          error: (err) => console.log(err)
        })
      }
    });
  }

  private startNewTimesheet() {
    this.timesheetService.start({}).subscribe((result:any) => {
      if (result) {
        this.startTimer(new Date(result.start.replace(' ', 'T')));
      }
    }, console.error);
  }

  private startTimer(start:any) {
    this.loading = false;
    this.start = start;
    this.timerSubscription = interval(1000).subscribe(() => this.timer = this.calculateDifference());
  }

  private calculateDifference() {

    const elapsedTime = Math.floor((new Date().getTime() - this.start.getTime()) / 1000);
    const hours = String(Math.floor(elapsedTime / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0');
    const seconds = String(elapsedTime % 60).padStart(2, '0');

    return { hours, minutes, seconds };
  }

  stopTimer(): void {
    this.timerSubscription?.unsubscribe();
    if (this.timer.seconds !== '00') {
      this.timer.seconds = '00';
      this.timer.minutes++;
    }
    this.stopped = true;
  }

  save() {
    this.timesheetService.stop(this.timesheetForm.value).subscribe({
      next: (result) => { 
        console.log(result)
        this.saved = true;
        this.timesheetForm.reset();
        this.timer = null;
        this.loading = false;
        this.open = false;
        this.stopped = false;
      },
      error: (err) => console.log(err)
    })
  }
}
