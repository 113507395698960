import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  getAll() {
    return this.get(`users`)
  }

  save(data: FormData) {
    return this.post(`users`, data)
  }

  register(data: FormData) {
    return this.post(`users/register`, data)
  }

  validatePin(data:any){
    console.log(data);
    return this.post(`pin/validate`, data);
  }

  updateUser(data: FormData) {
    return this.post(`users`, data)
  }

  getById(id: number) {
    return this.get(`users/${id}`)
  }

  getByHashId(hashid: number) {
    return this.get(`users/h/${hashid}`)
  }

  getByGroup(name: string) {
    return this.get(`users/group/${name}`)
  }

  getAllGroups() {
    return this.get(`groups`)
  }

  deleteUser(id: any) {
    return this.delete(`users/${id}`)
  }

  deleteById(id: any) {
    return this.delete(`users/${id}`)
  }
}

