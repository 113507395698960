<h3 class="text-uppercase fw-bold text-lg" *ngIf="list">Comentários</h3>
<div class="container-fluid p-0 chat border bg-white">
  <div class="row px-3" *ngIf="list && comments.length > 0">
    <div class="col-12 p-0">
      <ul #chatList class="list-unstyled m-0 mb-2 px-2 pt-3">
        <li class="d-flex mb-4" [class.flex-row-reverse]="comment?.user_id === user?.id" *ngFor="let comment of comments">
          <img [src]="comment.user.avatar || 'https://t4.ftcdn.net/jpg/05/89/93/27/360_F_589932782_vQAEAZhHnq1QCGu5ikwrYaQD0Mmurm0N.webp'" alt="avatar" class="avatar me-3" [ngClass]="{'ms-3': comment.user_id === user.id}">
          <div class="card w-100 shadow-none ps-4" [ngClass]="comment.user_id === user.id ? 'user-comment' : 'comment'">
            <div class="card-header d-flex justify-content-between bg-transparent p-0">
              <span class="fw-bold mb-0">{{comment.user.name}}</span>
              <span class="small mb-0 timestamp"><i class="far fa-clock"></i> {{comment.created_at | date:'dd/MM/yyyy'}}, às {{comment.created_at | date:'HH\'h\'mm'}}</span>
            </div>
            <div class="card-body p-0 pe-5">
              <p class="mb-0">{{comment.comment}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="chatForm" (ngSubmit)="sendComment(chatForm)">
        <div class="form-group d-flex border-top m-0">
          <textarea 
          class="form-control border-0 px-4 shadow-none" 
          formControlName="comment" 
          placeholder="Escreva aqui seu comentário..." 
          [class.is-invalid]="chatForm.get('comment')?.invalid && chatForm.get('comment')?.touched" 
          rows="1"
          [disabled]="loading"
          (keydown.enter)="sendComment(chatForm); $event.preventDefault();"
        ></textarea>
          <button type="submit" class="border-0 bg-transparent pe-3" [disabled]="chatForm.invalid">
            <img src="../../../../assets/argon-dashboard-master/assets/img/claro/icon-submit-comment.svg" alt="Enviar Comentário">
          </button>
        </div>
      </form>
    </div>
  </div>
</div>