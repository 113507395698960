<ngx-skeleton-loader 
*ngIf="skeleton"
count="5" 
appearance="line" 
[theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
</ngx-skeleton-loader>
<form [formGroup]="demandForm" class="card" *ngIf="!skeleton">
    <div class="card-header d-flex px-5 align-items-center py-4">
        <h1 class="title">
            <span class="fs-3 text-uppercase d-block">Solicitações</span>
            <span class="fs-1 text-uppercase d-block">Demandas Dac</span>
        </h1>
      <img src="../../../../assets/argon-dashboard-master/assets/img/claro/claro-radial-lines.png" class="ms-auto" alt="">
    </div>
    <div class="card-body p-5">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12">
                <div class="form-group mt-4 mb-0">
                  <label>
                    <strong>*Nome da demanda</strong>
                    <span class="fw-normal d-block">Inserir o nome para essa demanda.</span>
                  </label>
                  <input type="text" class="form-control" id="name" formControlName="name" placeholder="Digite aqui...">
                </div>
              </div>
            <div class="col-12">
              <div class="form-group mt-4 mb-0">
                <label>
                  <strong>*Informe o seu nome</strong>
                  <span class="fw-normal d-block">Inserir o nome do demandante da Solicitação / Projeto.</span>
                </label>
                <input type="text" class="form-control" id="requester" formControlName="requester" placeholder="Digite aqui...">
              </div>
            </div>
            <div class="col-12">
                <div class="form-group mt-4 mb-0">
                    <label>
                    <strong>*Informe seu email</strong>
                    </label>
                    <input type="email" class="form-control" id="email" formControlName="email" placeholder="Digite aqui...">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mt-4 mb-0 tel">
                    <label for="phone">
                        <strong>*Seu Telefone para Contato</strong>
                    </label>
                    <div class="d-flex">
                        <ng-select [items]="countries"
                            bindLabel="emoji"
                            bindValue="code"
                            formControlName="region"
                            [searchable]="false"
                            [clearable]="false"
                            class="countries-icons">
                            </ng-select>
                        <input type="text" class="form-control ms-2" id="name" placeholder="(99) 99999-9999" mask="(00) 00000-0000" formControlName="phone">
                    </div>
                </div>
                </div>
               <div class="col-12">
                    <label class="mt-4 mb-0 fw-bold">
                        <strong>*Criticidade da Demanda?</strong>
                    </label>
                    <div class="row criticality">
                        <div *ngFor="let c of criticalityOptions; let i = index" class="col-6">
                            <div class="form-group d-flex align-items-center mt-2 mb-0">
                                <div class="form-check mb-1">
                                    <input class="form-check-input" formControlName="criticality" name="criticality" type="radio" id="c-{{i}}" [value]="c.id">
                                    <label class="m-0 fw-normal" role="button" for="c-{{i}}">
                                        <span class="me-2">{{ c.label }}</span>
                                        <svg class="{{c.slug}}" width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H17V20L8.5 16.4706L0 20V0Z"/>
                                        </svg>                                            
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
                <div class="col-12">
                    <ng-container formArrayName="categories">
                        <div class="col-lg-12" *ngFor="let c of categories; let parentIndex = index">
                          <div class="form-group mt-4 mb-0">
                            <label>{{ c.required ? '*' : '' }} {{ c?.main_label }}</label>
                            <label class="fw-normal d-block" *ngIf="c.sub_label">{{ c.required ? '*' : '' }} {{ c?.sub_label }}</label>
                      
                            <ng-container [ngSwitch]="c.input">
                              <div *ngSwitchCase="'checkbox'">
                                <div class="form-check mb-1" *ngFor="let item of c.values; let childIndex = index">
                                  <input class="form-check-input" 
                                    (change)="onChange(c, item.id, $event)" 
                                    [type]="c.multiple ? 'checkbox' : 'radio'"
                                    name="category-{{parentIndex}}"
                                    id="category-{{parentIndex}}-options-{{childIndex}}">
                                  <label class="fw-normal" role="button" for="category-{{parentIndex}}-options-{{childIndex}}">
                                    {{ item.name }}
                                  </label>
                                </div>
                              </div>
                              <ng-select *ngSwitchCase="'select'"
                                [items]="c.values"
                                [multiple]="c.multiple"
                                bindLabel="name"
                                bindValue="id"
                                [(ngModel)]="c.selecteds"
                                [ngModelOptions]="{standalone:true}"
                                [closeOnSelect]="false"
                                [placeholder]="'Selecione os itens'">
                              </ng-select>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>            
                </div>
                <div class="col-12">
                    <div class="form-group mt-4 mb-0">
                        <label>
                            <strong>Classifique a demanda</strong>
                        </label>
                        <app-emoji-selector (emojiSelected)="addEmoji($event)"></app-emoji-selector>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group mt-4 mb-0">
                        <label class="form-label mb-3">Tem alguma apresentação ou documento deste tema?</label>
                        <div class="center">
                            <app-file-upload [formData]="formData"></app-file-upload>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button class="btn btn-sx btn-primary mb-0 text-uppercase py-2 px-4 ms-auto w-100 mt-2" (click)="save(demandForm)" [disabled]="demandForm.invalid">Enviar</button>
                </div>
                <div class="col-12">
                    <div class="box-alert p-4 mt-6">
                        <img src="../../../../assets/argon-dashboard-master/assets/img/claro/icon-alert.svg" alt="" class="icon-alert">
                        <p class="message pe-3">Nunca envie senhas ou dados confidenciais por meio de formulários desconhecidos. Certifique-se de que este formulário foi gerado por sua empresa ou por uma empresa confiável.</p>
                        <a href="#" class="me-5">Denunciar abuso</a>
                        <a href="#">Termos e condições</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>