import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss']
})
export class EmojiSelectorComponent implements OnInit {

  emojis:any = [
    { "id": 1, "emoji": "😃", "name": "Grinning Face with Big Eyes" },
    { "id": 2, "emoji": "🤣", "name": "Rolling on the Floor Laughing" },
    { "id": 3, "emoji": "🥲", "name": "Rosto Sorridente Com Lágrima" },
    { "id": 4, "emoji": "😎", "name": "Smiling Face with Sunglasses" },
    { "id": 5, "emoji": "🥶", "name": "Cold Face" },
    { "id": 6, "emoji": "🤨", "name": "Rosto Com Sobrancelha Levantada" },
    { "id": 7, "emoji": "🙂", "name": "Slightly Smiling Face" },
    { "id": 8, "emoji": "👍", "name": "Thumbs Up" },
    { "id": 9, "emoji": "🤞", "name": "Crossed Fingers" },
    { "id": 10, "emoji": "🫶", "name": "Mãos Em Coração" },
    { "id": 11, "emoji": "🤩", "name": "Rosto Com Olhar Maravilhado" },
    { "id": 12, "emoji": "🤔", "name": "Rosto Pensativo" },
    { "id": 13, "emoji": "😱", "name": "Rosto Gritando De Medo" },
    { "id": 14, "emoji": "🤯", "name": "Cabeça Explodindo" },
    { "id": 15, "emoji": "😵", "name": "Rosto Com Olhos Em Espiral" },
    { "id": 16, "emoji": "👑", "name": "Coroa" },
    { "id": 17, "emoji": "🌟", "name": "Estrela Brilhante" },
    { "id": 18, "emoji": "💸", "name": "Dinheiro Voando" },
    { "id": 19, "emoji": "❤️", "name": "Coração Vermelho" },
    { "id": 20, "emoji": "✅", "name": "Marca De Seleção" },
    { "id": 21, "emoji": "🥷", "name": "Ninja" },
    { "id": 22, "emoji": "🎅", "name": "Santa Claus" },
    { "id": 23, "emoji": "🧟‍♀️", "name": "Woman Zombie" },
    { "id": 24, "emoji": "🍀", "name": "Four Leaf Clover" },
    { "id": 25, "emoji": "🔥", "name": "Fire" },
    { "id": 26, "emoji": "🌈", "name": "Rainbow" },
    { "id": 27, "emoji": "💣", "name": "Bomb" },
    { "id": 28, "emoji": "🎉", "name": "Party Popper" },
    { "id": 29, "emoji": "👻", "name": "Fantasma" },
    { "id": 30, "emoji": "👽", "name": "Alien Monster" }
  ]

  @Output() emojiSelected = new EventEmitter<number>();

  ngOnInit(): void {
  }

  selected:any = null;
  show: boolean = false;

  toggleEmojiList() {
    this.show = !this.show;
  }

  select(emoji: any) {
    this.selected = emoji;
    this.show = false; // Fechar a lista após a seleção
    this.emojiSelected.emit(this.selected.id);
  }

}
