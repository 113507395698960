<div class="card z-index-2 h-100 mt-4">
  <div class="card-header pb-2 pt-3 bg-transparent">
    <div class="row align-items-center table-filter">
      <div class="col-2">
        <h6 class="text-capitalize text-xs mb-0">{{ title }}</h6>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <select class="form-control" #order (change)="orderItens(order.value)">
          <option value="desc">Mais recentes</option>
          <option value="asc">Mais antigas</option>
        </select>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <select name="" id="" class="form-control">
          <option value="0">Filtre por área</option>
        </select>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <input type="date" class="form-control" placeholder="Filtre por data">
      </div>
      <div class="col-4 d-flex justify-content-end">
        <input type="text" placeholder="Busque aqui" (keyup.enter)="searchItens(search.value)" #search class="form-control">
      </div>
      <!-- <div class="col-4 d-flex justify-content-end">
        <a routerLink="/calendar" class="btn btn-xs btn-primary mb-0 me-2" *ngIf="!solicitations">
          <i class="fas fa-calendar pe-2" aria-hidden="true"></i> Calendário
        </a>
        <a routerLink="/demands/new" class="btn btn-xs btn-primary mb-0" *ngIf="!solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Nova demanda
        </a>
        <a class="btn btn-xs btn-primary mb-0" (click)="copyToClipboard()" *ngIf="solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Compartilhar
        </a>
      </div> -->
    </div>
  </div>
  <div class="card-body p-3 pb-4">  

    <ngx-skeleton-loader 
      *ngIf="skeleton"
      count="5" 
      appearance="line" 
      [theme]="{height: '45px'}">
    </ngx-skeleton-loader>
    
    <div class="table-responsive p-0" *ngIf="!skeleton">
        <table class="table align-items-center mb-0" *ngIf="demands?.data.length > 0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Demanda</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prazo</th>
              <!-- <th></th> -->
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Envolvidos</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of demands.data" class="pointer">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm py-2 pointer" (click)="showDemand(d.id, content)">
                      <span class="tag-demand me-2 text-primary criticality">
                        <i class="fa fa-bookmark" [class]="'_' + d.priority" aria-hidden="true"></i>
                      </span>
                      <small class="pe-1">{{ d.job_code }}</small> {{ d.name }}
                    </h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ d.date | date: "dd/MM/yyyy" }}</span>
              </td>
              <!-- <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-danger" *ngIf="d.priority">Prioridade</span>
              </td> -->
              <td class="align-middle text-center text-sm">
                <div class="avatar-group ms-auto">
                  <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                    <img [alt]="u?.name" [title]="u?.name" [src]="u?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-status" *ngIf="context == 'workflow' && d.workflow" [style.background]="d.workflow.color">
                  {{ d.workflow.name }}
                </span>
                <span class="badge badge-status" *ngIf="context == 'demand' && d.status" [style.background]="d.status.color">
                  {{ d.status.name }}
                </span>
                <span class="badge badge-status" *ngIf="!context" [style.background]="d.workflow?.color || d.status.color">
                  {{ d.workflow?.name || d.status.name }}
                </span>
              </td>
              <td class="align-middle text-center d-none">
                <button class="btn btn-link text-secondary mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data.length == 0">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="demands.current_page == p">
                <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>

<ng-template #content let-modal>
  <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" (updateCalendar)="getDemands()" [modal]="true"></app-demands-form>
</ng-template>