import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DemandsService extends BaseService {

  getAll(filter: any = null) {
    return this.post(`demands/filter`, filter)
  }

  getById(id: number) {
    return this.get(`demands/${id}`)
  }

  getText(id: number) {
    return this.get(`demands/text/${id}`)
  }

  updateStatus(data: any) {
    return this.post(`demands/status`, data)
  }

  save(data: FormData) {
    return this.post(`demands`, data)
  }

  reject(id: number, data: any) {
    return this.post(`demands/reject/${id}`, data)
  }

  approved(id: number, data: any) {
    return this.post(`demands/approved/${id}`, data)
  }

  saveSolicitation(data: FormData) {
    return this.post(`demands/solicitations`, data)
  }

  update(data: FormData, id: number) {
    return this.post(`demands/${id}`, data)
  }

  updateContent(id: number, data: any) {
    return this.post(`demands/content/${id}`, data)
  }

  deleteById(id: number) {
    return this.delete(`demands/${id}`)
  }

  test(id: number) {
    return this.post(`test-update-demand/${id}`, {})
  }

}
