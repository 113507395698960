import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {

  getByDemandId(demand_id: number) {
    return this.get(`tasks/${demand_id}`)
  }

  getTasksStatusCount() {
    return this.get('tasks/status/count');
  }

  save(formData: FormData) {
    return this.post('task', formData)
  }

  deleteByTaskId(id: number) {
    return this.delete(`task/${id}`)
  }

}
