import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends BaseService {

  getAll(filter: any = null) {
    return this.post(`status/filter`, filter)
  }

  getById(id: number) {
    return this.get(`status/${id}`)
  }

  save(data: FormData) {
    return this.post(`status`, data)
  }

  deleteById(id: number) {
    return this.delete(`status/${id}`)
  }

  getKanban(context: string) {
    return this.get(`kanban/${context}`)
  }

  getMetrics(filter: any = null) {
    return this.post(`status/metrics`, filter)
  }

  setNewOrder(data: any) {
    return this.post(`kanban/order`, data)
  }

  setHighlight(data: any) {
    return this.post(`status/set/highlight`, data)
  }

}
