
<div class="modal-demand-form">

    <ngx-skeleton-loader *ngIf="skeleton" count="5" appearance="line"
    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
    </ngx-skeleton-loader>
    <div class="modal-header py-0 px-5" *ngIf="!skeleton">
        <span class="tag-demand me-3 criticality">
            <i class="fa fa-bookmark" [class]="'_' + demand?.criticality" aria-hidden="true"></i>
        </span>
        <h6 class="modal-title mb-0 me-auto">
            {{ demand?.name }}
        </h6>
        <div class="view">
            <div class="row">
                <div class="col-9 d-flex align-items-center">
                    <span class="text-white fw-bold ms-auto text-xs d-flex align-items-center me-3">
                        <i class="fa fa-calendar me-2" aria-hidden="true"></i>
                        {{ demand?.created_at | date: 'dd/MM/y' }}
                    </span>
                    <div class="badge py-2 px-4" [style.backgroundColor]="demand.workflow?.color ?? demand.status?.color">
                        {{ demand.workflow?.name ?? demand.status?.name }}
                    </div>
                </div>
                <!-- <div class="col-3">
                    <button type="button" *ngIf="showCloseButton" class="btn btn-sm btn-primary mb-0" (click)="cancel()" aria-label="Close">x</button>
                </div> -->
            </div>
        </div>
    </div>

    <div class="modal-body px-5" *ngIf="!skeleton">
        <div class="row" *ngIf="showRequester">
            <div class="col-12 requester">
                <img class="me-3" [src]="demand.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" alt="">
                <div>
                    <p class="mb-0">{{ demand.user?.name }}</p>
                    <p class="mb-0 text-xs">{{ demand.user?.email }} | {{ demand.user?.phone | mask: '(00) 00000-0000' }}</p>
                </div>
            </div>
        </div>
        <hr class="horizontal dark my-3">
        <div class="row">
            <div class="col-lg-12 col-12 mx-auto">
                <div class="card card-body z-index-2 h-100 pb-4 pt-0 shadow-none px-0">
                    <form [formGroup]="demandForm" *ngIf="!skeleton">
                        <div class="row align-items-end mb-4">
                            <ng-container>
                                <div class="col-lg-4" *ngFor="let c of categories; let i = index">
                                    <label class="form-label mt-4 mb-1">{{c.name}}</label>
                                    <ng-multiselect-dropdown [placeholder]="'Selecione os itens'"
                                        [settings]="dropdownSettings" [data]="c.values"
                                        [ngModelOptions]="{standalone: true}" [disabled]="true" [(ngModel)]="c.values">
                                    </ng-multiselect-dropdown>
                                </div>
                            </ng-container>
                        </div>

                        <hr class="horizontal dark my-3">

                        <div class="row">
                            <div class="tasks" *ngIf="tasks" [ngClass]="{'col-lg-5': tasks && tabs, 'col-lg-12': tasks && !tabs}">
                                <app-tasks [demand_id]="demand_id"></app-tasks>
                            </div>
                            <div class="pill-menu" *ngIf="tabs" [ngClass]="{'col-lg-7': tasks && tabs, 'col-lg-12': tabs && !tasks}">
                                <ul class="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase active" id="pills-briefing-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-briefing" type="button" role="tab"
                                            aria-controls="pills-briefing" aria-selected="true">Briefing</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase" id="pills-activities-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-activities" type="button"
                                            role="tab" aria-controls="pills-activities"
                                            aria-selected="false">Atividades</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase" id="pills-recurring-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-recurring" type="button" role="tab"
                                            aria-controls="pills-recurring" aria-selected="false">Recorrências</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase" id="pills-history-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-history" type="button" role="tab"
                                            aria-controls="pills-history" aria-selected="false">Histórico</button>
                                    </li>
                                </ul>
                                <div class="tab-content px-3" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-briefing" role="tabpanel" aria-labelledby="pills-briefing-tab" tabindex="0">
                                        <ckeditor [editor]="Editor" formControlName="content" [formControl]="contentControl"></ckeditor>
                                        <p *ngIf="showMessageSaveBriefing" class="alert alert-warning text-light font-weight-bold text-xs mt-1 py-1">Salvando conteúdo ...</p>
                                    </div>
                                    <div class="tab-pane fade" id="pills-activities" role="tabpanel" aria-labelledby="pills-activities-tab" tabindex="0">
                                        <button class="btn-add">+ Adicionar agenda</button>
                                        <div class="card">
                                            <div class="card-body d-flex">
                                                <ng-select [items]="items" bindLabel="name" bindValue="id"
                                                    formControlName="area" addTagText="Adicionar" [clearable]="false">
                                                </ng-select>
                                                <div class="form-group date">
                                                    <img src="assets/argon-dashboard-master/assets/img/claro/icon-calendar.svg"
                                                        alt="">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-recurring" role="tabpanel" aria-labelledby="pills-recurring-tab" tabindex="0">
                                        ...
                                    </div>
                                    <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab" tabindex="0">
                                        <app-history [demandId]="demand_id"></app-history>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="horizontal dark my-5">

                        <div class="row">
                            <div class="attachment-list" *ngIf="attachments" [ngClass]="{'col-lg-6': attachments && chats, 'col-lg-12': attachments && !chats}">
                                <app-file-upload [files]="demand_files" [context]="{context: 'demands', id: demand_id}" [style]="2"></app-file-upload>
                            </div>
                            <div *ngIf="chats" [ngClass]="{'col-lg-6': attachments && chats, 'col-lg-12': chats && !attachments}">
                                <app-chat [demandId]="demand_id"></app-chat>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="!skeleton">
        <div class="row" *ngIf="showActionsButtons && demand?.worflow?.identifier !== 'finalizada'">
            <div class="col-12 text-end">
                <button class="btn-action btn-rating me-4">Enviar avaliação</button>
                <button class="btn-action btn-finish" (click)="finish()">Concluir demanda</button>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6">
                <button type="button" name="button" *ngIf="!modal" [disabled]="loading" routerLink="/demands"
                    class="btn btn-light m-0">
                    Voltar
                </button>
                <!-- <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/demands" 
                        class="btn btn-danger ms-2 m-0">
                            Arquivar
                </button> -->
            </div>
            <div class="col-lg-6 col-12 mx-auto d-none">

                <div class="d-flex justify-content-end">
                    <button type="button" name="button" [disabled]="loading" (click)="cancel()" class="btn btn-light m-0">
                        {{ modal ? 'Fechar' : 'Cancelar' }}
                    </button>
                    <button type="button" name="button" *ngIf="approved" [disabled]="loading || !demandForm.valid"
                        (click)="save(demandForm)" class="btn bg-gradient-primary m-0 ms-2">
                        <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status" aria-hidden="true">
                        </span>
                        {{ loading ? 'Salvando' : 'Salvar demanda' }}
                    </button>
                    <button type="button" name="button" *ngIf="!approved" [disabled]="loading || !demandForm.valid"
                        (click)="save(demandForm)" class="btn bg-gradient-primary m-0 ms-2">
                        <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status" aria-hidden="true">
                        </span>
                        {{ loading ? 'Aprovando' : 'Aprovar demanda' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>