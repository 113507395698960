// app.module.ts

import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorModule } from './interceptors/interceptor.module';
import { SharedModule } from './shared/shared.module'; // Importando o SharedModule

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ErrorComponent } from './pages/error/error.component';

import localept from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from './pages/register/register.component';
import { OtpComponent } from './pages/otp/otp.component';


registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    RegisterComponent,
    OtpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    InterceptorModule,
    SharedModule, // módulos compartilhados
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ["localhost:4200", "smarthub.alamo.com.vc", "127.0.0.1:8000"],
        authScheme: "Bearer"
      }
    })
  ],
  providers: [{ 
    provide: LOCALE_ID, useValue: 'pt' 
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
