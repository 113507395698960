<h5 class="mb-3">TAREFAS</h5>

<div class="tasks">
    <div class="card shadow-none task-row task-row-create" 
        *ngFor="let t of tasks; let i = index"
        [style.borderColor]="t.status?.color || '#ccc'" 
        [style.backgroundColor]="t.status?.color + '1A' || '#ccc'">

        <div class="card-body d-flex align-items-center justify-content-between py-2 px-3">
            <div class="d-flex align-items-center">
                
                <input type="radio" 
                       name="is_completed-{{ t.id }}" 
                       class="form-check-input me-2"
                       (change)="isCompleted(t)" 
                       [disabled]="t.is_completed" 
                       [checked]="t.is_completed">

                <div class="dropdown me-2">
                    <button class="btn bg-transparent shadow-none dropdown-toggle m-0 fw-normal"
                        [disabled]="t.is_completed" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false">
                            {{ t.status?.name || 'Selecione' }}
                    </button>

                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item pointer" 
                                *ngFor="let s of status" 
                                (click)="setStatus(s.id, t)">{{ s.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="d-flex align-items-center">
                <input type="date" 
                       class="form-control date" #date 
                       [value]="t.due_date"
                       (change)="setDate(date.value, t)">
            </div>
            <div class="box-users">
                <img [src]="t.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar"
                     [title]="t.user?.name || ''" 
                     (click)="toggleUserList(i, $event)">

                <div class="list shadow" *ngIf="activeTaskIndex === i && !t.is_completed">
                    <h5 class="p-2">Envolvidos</h5>
                    <div class="item d-flex p-2 align-items-center pointer" 
                        *ngFor="let u of users"
                        (click)="setUser(u.id, t)">
                        <img [src]="t.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar avatar-sm">
                        <span class="ms-2 text-xs">{{ u.name }} {{ u.surname }}</span>
                    </div>
                </div>
            </div>

            <button class="btn mb-0 remove-task pointer" 
                    [disabled]="t.is_completed"
                    (click)="removeTask(t.id)">x</button>
        </div>
    </div>

    <button class="btn-add" (click)="addTask()">+ Adicionar tarefa</button>
</div>