import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { TaskService } from '../../services/demands/task.service';
import { StatusService } from '../../services/demands/status.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  @Input() demand_id: number = 0
  tasks:  any  = []
  status: any = []
  users:  any  = []
  activeTaskIndex: number | null = null;
  skeleton = true

  constructor(
    private service: TaskService,
    private statusService: StatusService,
    private usersService: UserService,
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.getByDemandId()
    this.getUsers()
    this.getStatus()
  }

  getStatus() {
    this.statusService.getAll({ 
      context: 'task',
      limit: 1000 
    }).subscribe({
      next: (result: any) => {
        this.status = result
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  getByDemandId() {
    this.service.getByDemandId(this.demand_id).subscribe({
      next: (result: any) => {
        this.tasks = result
        this.skeleton = false
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  getUsers() {
    this.usersService.getAll().subscribe({
      next: (result: any) => {
        console.log(result)
        this.users = result.data
      },
      error: (err) => console.log(err)
    })
  }

  addTask() {
    this.skeleton = true
    let task: any = {
      demand_id: this.demand_id,
      status_id: 0,
      user_id: 0,
      due_date: '2024-09-10',
      is_completed: 0
    }

    this.save(task)
   
  }

  save(task: any) {
    this.service.save(task).subscribe({
      next: (result) => {
        console.log(result)
        this.getByDemandId()
      },
      error: (err) => console.log(err)
    })
  }

  removeTask(id: number) {
    this.service.deleteByTaskId(id).subscribe({
      next: () => {
        this.getByDemandId()
      },
      error: (err) => console.log(err)
    })
  }

  isCompleted(task: any) {
    task.is_completed = !task.is_completed 
    this.save(task)
  }

  setStatus(status_id: number, task: any) {
    task.status_id = status_id
    this.save(task)
  }

  setDate(due_date: any, task: any) {
    task.due_date = due_date
    this.save(task)
  }

  setUser(user_id: number, task: any) {
    task.user_id = user_id
    this.activeTaskIndex = null;
     this.save(task)
  }

  toggleUserList(index: number, event: MouseEvent) {
    event.stopPropagation();  // Evita que o clique no avatar feche a lista

    if (this.activeTaskIndex === index) {
      this.activeTaskIndex = null; // Se já estiver visível, oculta a lista
    } else {
      this.activeTaskIndex = index; // Exibe a lista apenas para o item clicado
    }
  }

  // Ouve cliques no documento inteiro
  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    // Verifica se o clique foi fora do componente
    if (this.activeTaskIndex !== null && !this.eRef.nativeElement.contains(event.target)) {
      this.activeTaskIndex = null;  // Fecha a lista
    }
  }

}
