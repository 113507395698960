<div class="g-sidenav-show bg-gray-100">
    <div *ngIf="!modal" class="min-height-300 bg-gradient-red position-fixed w-100"></div>
    <aside *ngIf="!modal" class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4" id="sidenav-main">
        <app-side-menu [menu]="menu"></app-side-menu>
    </aside>
    <main class="main-content position-relative border-radius-lg">
        <app-nav *ngIf="!modal" [title]="title"></app-nav>
        <div class="container-fluid py-4 pt-2">
            <ng-content></ng-content>
        </div>
    </main>
</div>