
<div class="row" *ngIf="skeleton">
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
</div>

<div class="kanban-container" cdkDropListGroup>
    <div class="row content g-3" cdkDropList (cdkDropListDropped)="onColumnDrop($event)" cdkDropListOrientation="horizontal" [cdkDropListData]="status" cdkDropListGroup>
        <div class="col-lg-4" *ngFor="let s of status">
            <h6 class="text-center" [style.color]="s.color"><i *ngIf="s.icon" [class]="s.icon"></i> {{ s.name }}</h6>
            <div class="card card-body p-3 bg-light shadow-none" cdkDrag [cdkDragData]="s">
                <div cdkDropList
                    class="item-drop"
                    autoScrollDisabled="true"
                    [cdkDropListData]="context == 'demand' ? s.demands : s.workflow_demands"
                    (cdkDropListDropped)="drop($event, s)">
                    <!-- <div cdkDrag [cdkDragData]="d" class="card pointer z-index-2 my-2 item"
                        [style.border]="s.color"
                        [style.background]="d.priority ? '#ff727254' : ''"
                        [routerLink]="'/demands/edit/' + d.id" *ngFor="let d of context == 'demand' ? s.demands : s.workflow_demands">
                        <div class="card-body p-3">
                            <h6 class="text-capitalize mb-0 kaban-title">{{ d.name }}</h6>
                            <p class="text-xs text-secondary mb-0">{{ d.date | date : 'dd/MM/yyyy' }}</p>
                        </div>
                    </div> -->
                    <div cdkDrag [cdkDragData]="d" class="card kanban-card pointer z-index-2 item"
                        [style.border]="s.color"
                        (click)="openDemandPopup ? showDemand(d.id, content) : ''" *ngFor="let d of context == 'demand' ? s.demands : s.workflow_demands">
                        <span class="tag-demand me-2 criticality">
                            <i class="fa fa-bookmark" [class]="'_' + d?.criticality" aria-hidden="true"></i>
                        </span>
                        <div class="card-body p-3">
                            <p class="font-weight-bolder text-sm mb-0">{{ d.job_code }}</p>
                            <h6 class="text-capitalize mb-0 kaban-title">{{ d.name }}</h6>
                            <p class="text-xs text-secondary mb-0 py-2">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                {{ d.created_at | date : 'dd/MM/yyyy' }}
                            </p>
                            <p class="text-xs">Área destinada a demanda</p>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="avatar-group">
                                    <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let t of d.task">
                                        <img alt="Image placeholder" [title]="t.user?.name" src="assets/argon-dashboard-master/assets/img/default-user.png">
                                    </div>
                                </div>
                                <div class="text-end">
                                    <i class="fa fa-comments" aria-hidden="true"></i> <span class="text-xs ms-1">{{ d?.chat_count }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" [modal]="true"></app-demands-form>
</ng-template>